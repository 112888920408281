import React from "react";
import styled from "styled-components";
import { FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import logo from "../../assets/images/vegaveta/vegaveta_image.png"; // Update this to the correct path of your image
import NewsletterSignup from "./NewsletterSignup";
import { useSelector } from "react-redux";
import { useUser, useClerk } from '@clerk/clerk-react';

const FooterContainer = styled.footer`
  background-color: #222122;
  color: white;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  border-top: 1px solid #333;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 50px;
  }
`;

const Logo = styled.img`
  width: 80px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const LogoLink = styled.a`
  text-decoration: none;
  margin-left: 10%; /* Default margin for mobile and small screens */

  @media (min-width: 768px) {
    margin-left: 20%; /* Adjusted margin for larger screens */
  }
`;


const Links = styled.div`
  display: contents;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 0;
    gap: 30px;
  }
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  padding: 5px;
  &:hover {
    color: #AC544C;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const SocialIcon = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1.5em;
  &:hover {
    color: #AC544C;
  }
`;

const Footer = () => {
  const { isSignedIn } = useUser();
  const isAuthenticated = useSelector((state) =>{ 

    return isSignedIn });
  
  // const isAuthenticated = useSelector((state) =>{ 
  //   console.log(state,'--');    
  //   return state.Login.isAuthenticated; });
  return (
    <>
    {!isAuthenticated && <NewsletterSignup/>}
    <FooterContainer>
            {isAuthenticated && 
      <LogoLink href="/market">
        <Logo src={logo} alt="Logo" />
      </LogoLink>}
      {!isAuthenticated && 
      <LogoLink href="/">
        <Logo src={logo} alt="Logo" />
      </LogoLink>}
      <Links>
        <FooterLink href="/careers">Careers</FooterLink>
        <FooterLink href="/media">Media</FooterLink>
        <FooterLink href="/legal">Legal</FooterLink>
        <FooterLink href="/contact">Contact</FooterLink>
      </Links>
      <SocialIcons>
        <SocialIcon
          href="https://www.linkedin.com"
          target="_blank"
          aria-label="LinkedIn"
        >
          <FaLinkedin />
        </SocialIcon>
        <SocialIcon
          href="https://www.twitter.com"
          target="_blank"
          aria-label="Twitter"
        >
          <FaTwitter />
        </SocialIcon>
        <SocialIcon
          href="https://www.youtube.com"
          target="_blank"
          aria-label="YouTube"
        >
          <FaYoutube />
        </SocialIcon>
      </SocialIcons>
    </FooterContainer>
    </>
  );
};

export default Footer;
