import React, { useEffect, useState,  } from "react";
import styled from "styled-components";
import logo from "../../assets/images/vegaveta/logo.png"; // Update this to the correct path of your image
import LoginModal from "../Login/LoginModal"; // Update the path to your LoginModal component
import { Navigate, useLocation,useNavigate } from "react-router-dom";
import ThemeToggle from "../../Layouts/ThemeToggle";
import { FaBars } from 'react-icons/fa'; // Import the hamburger icon
import { useSelector, useDispatch } from 'react-redux';
import { logoutUserSuccess } from "../../slices/auth/login/reducer";
import { useUser, useClerk } from '@clerk/clerk-react';

// Styled components
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #222122;
  z-index: 1000;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px 15px;
    position: absolute;
  }
`;

const Logo = styled.img`
  width: 60px;
  margin-left: 20%;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 50px;
  }
`;

const ButtonContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Button = styled.button`
  background-color: #AC544C;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #b65e4d;
  }
`;

const Nav = styled.nav`
  display: contents;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    display: ${props => props.isNavOpen ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    background-color: #222122;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 999;
  }
`;

const NavLink = styled.a`
  color: white;
  text-decoration: none;
  padding: 10px;
  font-size: 1.2em;
  &:hover {
    color: #AC544C;
  }

  @media (max-width: 768px) {
    font-size: 1em;
    padding: 15px;
  }
`;

const SubNav = styled.div`
  display: flex;
  justify-content: center;
  background-color: #222122;
  padding: 10px 20% 20px 10px;
  position: absolute;
  top: 100%; /* Ensures it appears below the NavLink */
  left: 0;
  width: 100%;
  border-top: 1px solid #AC544C;
  z-index: 998; /* Ensures it stays on top of other elements */
`;

const SubNavLink = styled.a`
  color: white;
  text-decoration: none;
  margin: 0 15px;
  font-size: 1em;
  cursor: pointer;
  &:hover {
    color: #AC544C;
  }
`;

const LogoLink = styled.a`
  margin-left: 20%;
  text-decoration: none;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const HamburgerMenu = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5em;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Header = ({ layoutModeType, onChangeLayoutMode }) => {
  // Instead of using localStorage, manage authentication in React state
  // const dispatch = useDispatch();
  
  const location = useLocation();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isNewsSubMenuVisible, setIsNewsSubMenuVisible] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [redirectToMarket, setRedirectToMarket] = useState(false);  
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const { isSignedIn } = useUser();

  

  const isAuthenticated = useSelector((state) =>{ 

    return isSignedIn });

    

  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };


  const dispatch = useDispatch();

  // Define your event handler or callback function
  const handleLogout = () => {
                localStorage.removeItem('authToken');
                // <Navigate to={{ pathname: "/"}} />
      dispatch(logoutUserSuccess());
      setRedirectToMarket(true);  // Trigger redirect
      
  };
  if(isSignedIn &&  location.pathname === '/'){
    return <Navigate to="/market" />;  // Navigate to the market page
  }
  if (redirectToMarket) {
    window.location.reload();
    return <Navigate to="/market" />;  // Navigate to the market page
}


  const toggleSubMenu = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLogout1 = () => {
    signOut();
  };

  

  // Function to handle login
  // const handleLogin = () => {
  //   setIsAuthenticated(true); // Set to true when the user logs in
  // };

  // if (!isAuthenticated) {
  //   return <Navigate to="/login" />;
  // }

  // Protect the charts page, redirect if not authenticated
  // if (!isAuthenticated && location.pathname === "/chart") {
  //   return <Navigate to="/login" />;
  // }

  const subMenus = {
    news: [
      { name: "General", link: "/news/general" },
      { name: "Stock", link: "/news/stock" },
      { name: "Crypto", link: "/news/crypto" },
      { name: "Forex", link: "/news/forex" },
    ],
    portfolio: [
      { name: "Overall", link: "/overall" },
      { name: "Clients", link: "/clients" },
      { name: "Portfolio", link: "/portfolio" },
      
    ],
    pricing: [
      { name: "Basic", link: "/pricing/basic" },
      { name: "Premium", link: "/pricing/premium" },
      { name: "Enterprise", link: "/pricing/enterprise" },
    ],
    // Add more sub-menus as needed
    
  };

  return (
    <HeaderContainer>
      {isAuthenticated &&
      <LogoLink href="/market">
        <Logo src={logo} alt="Logo" />
      </LogoLink>}
      {!isAuthenticated &&
      <LogoLink href="/">
        <Logo src={logo} alt="Logo" />
      </LogoLink>}
      <HamburgerMenu onClick={toggleNav}>
        <FaBars />
      </HamburgerMenu>
      <Nav isNavOpen={isNavOpen}>
        {!isAuthenticated && <NavLink href="/">Home</NavLink> }
        {isAuthenticated && <NavLink href="/market">Market</NavLink>}
        {isAuthenticated && <NavLink href="#" onClick={() => toggleSubMenu("news")}>News</NavLink>}
        {!isAuthenticated && <NavLink href="/services">Services</NavLink>}
        {!isAuthenticated && <NavLink href="/pricing">Pricing</NavLink>}
        {!isAuthenticated && <NavLink href="/about">About Us</NavLink>}
        {/* Conditionally render the charts link only if authenticated */}
        {isAuthenticated && <NavLink href="/chart">Charts</NavLink>}
        {isAuthenticated && <NavLink href="#" onClick={() => toggleSubMenu("portfolio")}>Portfolio</NavLink>}
        {/* {isAuthenticated && <NavLink href="/portfolio">Portfolio</NavLink>} */}
        {isAuthenticated && <NavLink href="/global">Global</NavLink>}
        {/* {isAuthenticated && <NavLink href="/indicators">Ind</NavLink>} */}
        {isAuthenticated && <NavLink href="/articles">Articles</NavLink>}
        
        {!isAuthenticated &&
        <ButtonContainer>
          <Button>Start your free trial</Button>
        </ButtonContainer>}
        {location.pathname === '/' && (
          <ThemeToggle
            layoutMode={layoutModeType}
            onChangeLayoutMode={onChangeLayoutMode}
          />
        )}
        {/* {!isAuthenticated && <NavLink onClick={toggleLoginModal}>Login</NavLink>}
        {isAuthenticated && <NavLink onClick={handleLogout}>Logout</NavLink>} */}

<div>
      {isSignedIn ? (
        <NavLink onClick={handleLogout1}>Logout</NavLink>
      ) : (
        <NavLink onClick={() => window.Clerk.openSignIn()}>Login</NavLink>
      )}
    </div>
      </Nav>
      {activeMenu && (
        <SubNav>
          {subMenus[activeMenu].map((subMenuItem) => (
            <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
              {subMenuItem.name}
            </SubNavLink>
          ))}
        </SubNav>
      )}
      <LoginModal isOpen={isLoginModalOpen} toggle={toggleLoginModal} />    </HeaderContainer>
  );
};

export default Header;
