import React, { useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import { supabase } from '../supabaseClient';
import Header from './Header';
import Footer from '../Components/Common/Footer';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  min-height: 100vh;
  margin-top: 100px;
`;

const MainContent = styled.div`
  width: 80%;
  background-color: ${({ theme }) => theme.background};
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const HalfWidthContainer = styled.div`
  width: 45%;
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
};

const darkTheme = {
  background: '#222122',
  color: '#f0f0f0',
  primary: '#AC544C',
  secondary: '#b06252',
};

export const FormGroup = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;

export const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;

export const Button = styled.button`
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.secondary};
  }

`;
const Title = styled.h1`
  font-size: 2.5em;
  color: ${({ theme }) => '#fff'};
  margin: 10px;
  background: ${({ theme }) => theme.primary};
  width: 80%;
  text-align:center;

`;

const Title1 = styled.h2`
  font-size: 1.5em;
  color: ${({ theme }) => theme.color};
  margin: 10px;
  
  text-align:center;

`;

const Overall = () => {
  const [portfolioName, setPortfolioName] = useState('');
  const [location, setLocation] = useState('');
  const [household, setHousehold] = useState('');
  const [financialGoal, setFinancialGoal] = useState('');
  const [customFinancialGoal, setCustomFinancialGoal] = useState('');
  const [investmentStrategy, setInvestmentStrategy] = useState('');
  const [customInvestmentStrategy, setCustomInvestmentStrategy] = useState('');
  const [accountType, setAccountType] = useState('');
  const [otherPeople, setOtherPeople] = useState('');
  const [clientSegment, setClientSegment] = useState('');
  const [benchmark, setBenchmark] = useState('');
  const [portfolioModel, setPortfolioModel] = useState('');
  const [otherModel, setOtherModel] = useState('');
  const [equity, setEquity] = useState('');
  const [fixedIncome, setFixedIncome] = useState('');
  const [realEstate, setRealEstate] = useState('');
  const [alternativeInvestment, setAlternativeInvestment] = useState('');
  const [derivative, setDerivative] = useState('');
  const [cryptocurrency, setCryptocurrency] = useState('');
  const [cash, setCash] = useState('');

  const addData = async (e) => {
    console.log('dadsa');
    e.preventDefault();
    if (!portfolioName) {
      toast.error(`Portfolio name is empty`);
      return;
    }
    
    

    // Determine the final values for the fields that can have "Other" as an option
    const finalFinancialGoal = financialGoal === 'Other' ? customFinancialGoal : financialGoal;
    const finalInvestmentStrategy = investmentStrategy === 'Other' ? customInvestmentStrategy : investmentStrategy;
    const finalPortfolioModel = portfolioModel === 'Other' ? otherModel : portfolioModel;

    try {
        const { data, error } = await supabase
        .from("PortfolioIdentity")
        .insert([
          {
            Portfolio_Name: portfolioName,
            Location: location, // Ensure this matches the case
            Household: household, // Ensure this matches the case
            Financial_Goal: finalFinancialGoal, // Ensure this matches the case
            Investment_Strategy: finalInvestmentStrategy, // Ensure this matches the case
            Client_Segment: clientSegment, // Ensure this matches the case
            Portfolio_Model: finalPortfolioModel, // Ensure this matches the case
            Account_Types: accountType, // Ensure this matches the case
            Benchmark: benchmark, // Ensure this matches the case
            // Optional fields (provide values as necessary)
            Equity_Weight: equity || null, // Provide value or null
            Fix_Income_Weight: fixedIncome || null, // Provide value or null
            Real_Estate_Weight: realEstate || null, // Provide value or null
            Alternative_Investment_Weight: alternativeInvestment || null, // Provide value or null
            Derivative_Weight: derivative || null, // Provide value or null
            Cryptocurrency_Weight: cryptocurrency || null, // Provide value or null
            Cash_and_Cash_Equivalents_Weight: cash || null, // Provide value or null
          }
        ]);
      
        console.log(data,error);
        
      if (error) {
        throw error;
      }
      toast.success('Portfolio added successfully!');
      // Clear the form after submission
      setPortfolioName('');
      setLocation('');
      setHousehold('');
      setFinancialGoal('');
      setCustomFinancialGoal('');
      setInvestmentStrategy('');
      setCustomInvestmentStrategy('');
      setAccountType('');
      setOtherPeople('');
      setClientSegment('');
      setBenchmark('');
      setPortfolioModel('');
      setOtherModel('');
      setEquity('');
      setDerivative('');
      setFixedIncome('');
      setCash('');
      setAlternativeInvestment('');
        setCryptocurrency('');
      setRealEstate('');
    } catch (error) {
      toast.error(`Error inserting data: ${error.message}`);
    }
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <Container>
        <Header />
        <Title>Add New Portfolio</Title>
        <MainContent>
          
          <FormContainer>
            {/* First Row */}
            <HalfWidthContainer>
              <FormGroup>
                <Label>Portfolio Name</Label>
                <Input
                  type="text"
                  value={portfolioName}
                  onChange={(e) => setPortfolioName(e.target.value)}
                  required
                />
              </FormGroup>
            </HalfWidthContainer>
            <HalfWidthContainer>
              <FormGroup>
                <Label>Location</Label>
                <Input
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </FormGroup>
            </HalfWidthContainer>

            {/* Second Row */}
            <HalfWidthContainer>
              <FormGroup>
                <Label>Household</Label>
                <Input
                  type="text"
                  value={household}
                  onChange={(e) => setHousehold(e.target.value)}
                />
              </FormGroup>
            </HalfWidthContainer>
            <HalfWidthContainer>
              <FormGroup>
                <Label>Financial Goal</Label>
                <Select
                  value={financialGoal}
                  onChange={(e) => setFinancialGoal(e.target.value)}
                  required
                >
                  <option value="">Select Financial Goal</option>
                  <option value="Retirement">Retirement</option>
                  <option value="Education Saving">Education Saving</option>
                  <option value="Wealth Accumulation">Wealth Accumulation</option>
                  <option value="Major Purchase">Major Purchase</option>
                  <option value="Other">Other</option>
                </Select>
                {financialGoal === 'Other' && (
                  <Input
                    type="text"
                    placeholder="Enter Custom Financial Goal"
                    value={customFinancialGoal}
                    onChange={(e) => setCustomFinancialGoal(e.target.value)}
                    required
                  />
                )}
              </FormGroup>
            </HalfWidthContainer>

            {/* Third Row */}
            <HalfWidthContainer>
              <FormGroup>
                <Label>Investment Strategy</Label>
                <Select
                  value={investmentStrategy}
                  onChange={(e) => setInvestmentStrategy(e.target.value)}
                  required
                >
                  <option value="">Select Investment Strategy</option>
                  <option value="Value Investing">Value Investing</option>
                  <option value="Growth Investing">Growth Investing</option>
                  <option value="Income Investing">Income Investing</option>
                  <option value="ESG Investing">ESG Investing</option>
                  <option value="Other">Other</option>
                </Select>
                {investmentStrategy === 'Other' && (
                  <Input
                    type="text"
                    placeholder="Enter Custom Investment Strategy"
                    value={customInvestmentStrategy}
                    onChange={(e) => setCustomInvestmentStrategy(e.target.value)}
                    required
                  />
                )}
              </FormGroup>
            </HalfWidthContainer>

            {/* Fourth Row */}
            <HalfWidthContainer>
              <FormGroup>
                <Label>Account Type</Label>
                <Select
                  value={accountType}
                  onChange={(e) => setAccountType(e.target.value)}
                  required
                >
                  <option value="">Select Account Type</option>
                  <option value="Individual">Individual</option>
                  <option value="Joint Account">Joint Account</option>
                  <option value="Trust Account">Trust Account</option>
                </Select>
                {/* Conditionally display "Other People" field when Joint Account is selected */}
                {accountType === 'Joint Account' && (
                  <Input
                    type="text"
                    placeholder="Enter Other People's Names"
                    value={otherPeople}
                    onChange={(e) => setOtherPeople(e.target.value)}
                  />
                )}
              </FormGroup>
            </HalfWidthContainer>
            <HalfWidthContainer>
              <FormGroup>
                <Label>Client Segment</Label>
                <Input
                  type="text"
                  value={clientSegment}
                  onChange={(e) => setClientSegment(e.target.value)}
                />
              </FormGroup>
            </HalfWidthContainer>

            {/* Fifth Row */}
            <HalfWidthContainer>
              <FormGroup>
                <Label>Benchmark</Label>
                <Input
                  type="text"
                  value={benchmark}
                  onChange={(e) => setBenchmark(e.target.value)}
                />
              </FormGroup>
            </HalfWidthContainer>
            <HalfWidthContainer>
              <FormGroup>
                <Label>Portfolio Model</Label>
                <Select
                  value={portfolioModel}
                  onChange={(e) => setPortfolioModel(e.target.value)}
                  required
                >
                  <option value="">Select Portfolio Model</option>
                  <option value="Growth">Growth</option>
                  <option value="Moderate">Moderate</option>
                  <option value="Market">Market</option>
                  <option value="Conservative">Conservative</option>
                  <option value="Other">Other</option>
                </Select>
                {portfolioModel === 'Other' && (
                  <Input
                    type="text"
                    placeholder="Enter Custom Model"
                    value={otherModel}
                    onChange={(e) => setOtherModel(e.target.value)}
                    required
                  />
                )}
                
              </FormGroup>
            </HalfWidthContainer>            
            <HalfWidthContainer/>            

          <Title1>**Assets class Weights**</Title1>
          <HalfWidthContainer/>
          <HalfWidthContainer>
              <FormGroup>
                <Label>Equity</Label>
                <Input
                  type="number"
                  value={equity}
                  onChange={(e) => setEquity(e.target.value)}
                  placeholder="%"
                  required
                />
              </FormGroup>
              
            </HalfWidthContainer>
            <HalfWidthContainer>
              <FormGroup>
                <Label>Fixed Income</Label>
                <Input
                  type="number"
                  value={fixedIncome}
                  onChange={(e) => setFixedIncome(e.target.value)}
                  placeholder="%"
                  required
                />
              </FormGroup>              
           </HalfWidthContainer>              

           <HalfWidthContainer>
              <FormGroup>
                <Label>Real Estate</Label>
                <Input
                  type="number"
                  value={realEstate}
                  onChange={(e) => setRealEstate(e.target.value)}
                  placeholder="%"
                  required
                />
              </FormGroup>
              
            </HalfWidthContainer>
            <HalfWidthContainer>
              <FormGroup>
                <Label>Alternative Investment</Label>
                <Input
                  type="number"
                  value={alternativeInvestment}
                  onChange={(e) => setAlternativeInvestment(e.target.value)}
                  placeholder="%"
                  required
                />
              </FormGroup>              
              </HalfWidthContainer>


           <HalfWidthContainer>
              <FormGroup>
                <Label>Derivative</Label>
                <Input
                  type="number"
                  value={derivative}
                  onChange={(e) => setDerivative(e.target.value)}
                  required
                  placeholder="%"
                />
              </FormGroup>
              
            </HalfWidthContainer>
            <HalfWidthContainer>
              <FormGroup>
                <Label>Cryptocurrency</Label>
                <Input
                  type="number"
                  value={cryptocurrency}
                  onChange={(e) => setCryptocurrency(e.target.value)}
                  placeholder="%"
                  required
                />
              </FormGroup>              
              </HalfWidthContainer>

              <HalfWidthContainer>
              <FormGroup>
                <Label>Cash and Cash Equivalents</Label>
                <Input
                  type="number"
                  value={cash}
                  onChange={(e) => setCash(e.target.value)}
                  placeholder="%"
                  required
                />
              </FormGroup>              
              </HalfWidthContainer>

          </FormContainer>
          
          <Button onClick={addData}>Add Portfolio</Button>
        </MainContent>
        
      </Container>
      <Footer />
      <ToastContainer />
    </ThemeProvider>
  );
};

export default Overall;
