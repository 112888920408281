import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled components
const ThemeButton = styled.button`
  background-color: ${({ theme }) => (theme === 'light' ? '#ddd' : 'gray')};
  border: none;
  border-radius: 50px;
  width: 60px;
  height: 30px;
  position: relative;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: ${({ theme }) => (theme === 'light' ? 'flex-start' : 'flex-end')};
  transition: background-color 0.3s, justify-content 0.3s;

  & .toggle-icon {
    background-color: ${({ theme }) => (theme === 'light' ? '#fff' : '#333')};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    transition: transform 0.3s;
  }

  &:hover {
    background-color: ${({ theme }) => (theme === 'light' ? '#ccc' : '#555')};
  }
`;

const IconContainer = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 3px;
  left: ${({ theme }) => (theme === 'light' ? '3px' : 'calc(100% - 27px)')};
  transition: left 0.3s;
`;

// ThemeToggle Component
const ThemeToggle = ({ onChangeLayoutMode }) => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      return savedTheme ? JSON.parse(savedTheme) : 'light';
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return 'light';
    }
  });

  useEffect(() => {
    localStorage.setItem('theme', JSON.stringify(theme));
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    if (onChangeLayoutMode) {
      onChangeLayoutMode(newTheme);
    }
  };

  return (
    <ThemeButton onClick={toggleTheme} theme={theme}>
      <IconContainer theme={theme} className="toggle-icon">
        {theme === 'light' ? (
          <i className="bx bx-moon fs-22" /> // Example for moon icon
        ) : (
          <i className="bx bx-sun fs-22" /> // Example for sun icon
        )}
      </IconContainer>
    </ThemeButton>
  );
};

export default ThemeToggle;
