import React, { useEffect, useState } from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import bullBearImage from "./bull-bear.png";
import dynamicModel from "./dynamicModel.png";
import NewsSection from "../../Components/Common/NewsSection";
import Footer from "../../Components/Common/Footer";
import Header from "../Header";
import serviceImage4 from '../../assets/images/vegaveta/services/service1.png';
import serviceImage2 from '../../assets/images/vegaveta/services/service2.png';
import serviceImage1 from '../../assets/images/vegaveta/services/service3.png';
import serviceImage6 from '../../assets/images/vegaveta/services/service4.png';
import serviceImage7 from '../../assets/images/vegaveta/services/service5.png';
import serviceImage8 from '../../assets/images/vegaveta/services/service6.png';
import serviceImage9 from '../../assets/images/vegaveta/services/service7.png';
import serviceImage3 from '../../assets/images/vegaveta/services/service8.png';
import serviceImage5 from '../../assets/images/vegaveta/services/service9.png';

// Define your light and dark themes
const lightTheme = {
  background: "#ffffff",
  color: "#000000",
  cardBackground: "#f5f5f5",
  cardColor: "#000000",
  buttonBackground: "#AC544C",
  buttonHoverBackground: "#b65e4d",
};

const darkTheme = {
  background: "#222122",
  color: "#ffffff",
  cardBackground: "#222122",
  cardColor: "#ffffff",
  buttonBackground: "#AC544C",
  buttonHoverBackground: "#b65e4d",
};

// Global styles that depend on the theme
const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s, color 0.3s;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

// Styled components using theme properties
const Container = styled.div`
  text-align: center;
  padding: 50px;
  font-family: 'Lora', serif;
`;

const Main = styled.main`
  padding: 50px 20px;
`;

const Title = styled.h1`
  font-size: 3em;
  color: ${({ theme }) => theme.color};
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.2em;
  color: ${({ theme }) => theme.cardColor};
  font-weight: bold;
`;

const Subtitle = styled.p`
  font-size: 1.5em;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.color};
  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const SubTitleref = styled.p`
  font-size: 2.5em;
  margin: 70px 0;
  padding: 10px;
  color: ${({ theme }) => theme.color};
  @media (max-width: 768px) {
    font-size: 2em;
    margin: 40px 0;
  }
`;

const Subref = styled.p`
  font-size: 2em;
  margin: 70px 0;
  padding: 10px;
  color: ${({ theme }) => theme.color};
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.2em;
    margin: 20px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 1400px;
  margin-top: 30px;
`;

const Image1 = styled.img`
  width: 100%;
  max-width: 600px;
  margin-top: 30px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 40px auto;
  width: 90%;
`;

const Card = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.cardColor};
  border-radius: 8px;
  padding: 0;
  width: calc(33.333% - 20px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.cardColor};
  

  @media (max-width: 1000px) {
    width: calc(100% - 20px);
    height: fit-content; 
  }
  @media (max-width: 768px) {
    width: calc(50% - 20px);
    
  }
  @media (max-width: 480px) {
    width: calc(100% - 20px);
    height: fit-content;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 50%;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 20px;
  text-align: left;
`;

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.color};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBackground};
  }
`;

const Homepage = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      console.log(cleanTheme);
      
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });
  // const [theme, setTheme] = useState(lightTheme);

  console.log(theme);
  
  // useEffect(() => {
  //   const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  //   if (mediaQuery.matches) {
  //     setTheme(darkTheme);
  //   } else {
  //     setTheme(lightTheme);
  //   }

  //   const handleChange = (e) => {
  //     setTheme(e.matches ? darkTheme : lightTheme);
  //   };
  //   mediaQuery.addEventListener("change", handleChange);

  //   return () => {
  //     mediaQuery.removeEventListener("change", handleChange);
  //   };
  // }, []);

  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
        />
        <Main>
        <Title>Unlock the Future of Finance with VegaVeta's AI-Driven Analytics</Title>
          {/* <Title>Welcome to VegaVeta</Title>
          <Subtitle>Unlock the Future of Finance with AI-Driven Analytics</Subtitle> */}
          <Image src={bullBearImage} alt="Bull and Bear" />
          <Subref>
            At VegaVeta, we equip investors with advanced tools and expert insights to manage their stock portfolios effectively. Our platform provides comprehensive financial data, powerful analytics, and intuitive portfolio management solutions tailored to your investment needs. The clear, easy-to-use user interface of our dashboard ensures a seamless experience for all users.
          </Subref>
          <StyledButton>Schedule a Consultation</StyledButton>
          <SubTitleref>Why VegaVeta?</SubTitleref>
          <Subref>Quinbridge Dynamic Model</Subref>
          <Image1 src={dynamicModel} alt="Quinbridge Dynamic Model" />
          <Subref>
            The QuinBridge Dynamic Model is a cornerstone of our analytical framework, designed to integrate and synthesize information from multiple dimensions to deliver a holistic understanding of the financial landscape.
          </Subref>
          <Subref>
            See how VegaVeta can help your management firm reach new and exciting goals for your clients.
          </Subref>
          <StyledButton>Start your free trial</StyledButton>
          <CardContainer>
            <Card>
              <CardImage src={serviceImage1} alt="Image 1" />
              <CardContent>
                <CardTitle>Advanced Financial Data</CardTitle>
                <p>Access real-time and historical data on thousands of stocks, ETFs, and indices. Our robust analysis helps you make informed investment decisions, keeping you ahead of market trends.</p>
              </CardContent>
            </Card>
            <Card>
              <CardImage src={serviceImage9} alt="Image 9" />
              <CardContent>
                <CardTitle>Dynamic Charting Tools</CardTitle>
                <p>Visualize market trends and performance with our interactive charting tools. These visualizations help identify patterns and opportunities, optimizing your portfolio strategy.</p>
              </CardContent>
            </Card>
            <Card>
              <CardImage src={serviceImage2} alt="Image 2" />
              <CardContent>
                <CardTitle>Customizable Dashboards</CardTitle>
                <p>Tailor your dashboard to the metrics that matter most. Monitor portfolio performance, asset allocation, and market news all in one place for a comprehensive view of your investments.</p>
              </CardContent>
            </Card>
          </CardContainer>
          <CardContainer>
            <Card>
              <CardImage src={serviceImage3} alt="Image 3" />
              <CardContent>
                <CardTitle>Comprehensive Fundamental Analysis</CardTitle>
                <p>Our platform provides in-depth evaluations of individual companies, focusing on financial health, business models, industry positions, and growth prospects.</p>
              </CardContent>
            </Card>
            <Card>
              <CardImage src={serviceImage4} alt="Image 4" />
              <CardContent>
                <CardTitle>Regional Analysis</CardTitle>
                <p>Our regional analysis services offer deep insights into specific geographic markets. We evaluate economic and social factors influencing regional markets, providing a clear understanding of unique opportunities and risks.</p>
              </CardContent>
            </Card>
            <Card>
              <CardImage src={serviceImage5} alt="Image 5" />
              <CardContent>
                <CardTitle>In-Depth Portfolio Analysis</CardTitle>
                <p>Our portfolio analysis provides a thorough evaluation using advanced analytics like Monte Carlo simulations, Sharpe Ratio assessments, and Value at Risk (VaR) calculations.</p>
              </CardContent>
            </Card>
          </CardContainer>
          <CardContainer>
            <Card>
              <CardImage src={serviceImage6} alt="Image 6" />
              <CardContent>
                <CardTitle>Exclusive Market Insights</CardTitle>
                <p>Access our exclusive market insights and research reports covering a wide range of financial metrics and indicators. Our in-depth analysis keeps you informed and ahead of market trends.</p>
              </CardContent>
            </Card>
            <Card>
              <CardImage src={serviceImage7} alt="Image 7" />
              <CardContent>
                <CardTitle>Premier Economic Data</CardTitle>
                <p>Gain insights into macroeconomic indicators, trends, and forecasts with our economic data, covering GDP growth rates, inflation, employment statistics, and trade balances.</p>
              </CardContent>
            </Card>
            <Card>
              <CardImage src={serviceImage8} alt="Image 8" />
              <CardContent>
                <CardTitle>Weekly Chartbook</CardTitle>
                <p>Delivered weekly in a convenient PDF format, our Chartbook provides up-to-date charts and graphs for visual analysis of market trends and performance. This tool helps you stay informed and make data-driven investment decisions.</p>
              </CardContent>
            </Card>
          </CardContainer>
          <Subref>Our mission is to simplify intelligent investing.</Subref>
          <Subref>
            We push the boundaries of investment analysis and portfolio management by integrating advanced artificial intelligence technology with deep financial expertise. Our goal is to empower investors with tools and insights that enhance decision-making and investment performance.
          </Subref>
          <Subref>Join Us on Your Investment Journey</Subref>
          <StyledButton>Schedule a Consultation</StyledButton>
          <Subref>
            With VegaVeta, you enhance your competitive advantage and position yourself for the future with advanced real-time software, growing your business and offering exceptional service.
          </Subref>
        </Main>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default Homepage;
