import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { VectorMap } from '@south-paw/react-vector-maps';
import * as worldLowRes from '../common/world.svg.json';
import Header from './Header';
import { supabase } from '../supabaseClient';
import { FixedSizeList as List } from 'react-window';

// Define light and dark themes (same as before)
const lightTheme = {
  background: "#ffffff",
  color: "#000000",
  cardBackground: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
};

const darkTheme = {
  background: "#222122",
  color: "#ffffff",
  cardBackground: "#222122",
  cardColor: "#ffffff",
  borderColor: "#ffffff",
};

// Global styles
const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    margin: 0;
    height: 100%;
    transition: background-color 0.3s, color 0.3s;
  }
`;

// Container styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  overflow-y: auto;
  margin-top: 100px;
`;

// New styled component for side by side layout
const SideBySideContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  max-width: 1000px;
  margin-top: 20px;
`;

// Styled components for map and country map
const Map = styled.div`
  background: ${({ theme }) => theme.cardBackground};
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  padding: 10px;
  width: 60%; // Adjust width as needed

  svg {
    stroke: #fff;
    path {
      fill: #c0c0c0;
      cursor: pointer;
      outline: none;

      &:hover {
        fill: rgba(168, 43, 43, 0.83);
      }
      &:focus {
        fill: rgba(168, 43, 43, 0.6);
      }
      &[aria-checked='true'] {
        fill: rgba(56, 43, 168, 0.83);
      }
      &[aria-current='true'] {
        fill: rgba(56, 43, 168, 0.83);
      }
    }
  }
`;

const CountryMapContainer = styled.div`
  margin: 20px 0;
  background: ${({ theme }) => theme.cardBackground};
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  padding: 10px;
`;

const DropdownContainer = styled.div`
  background: ${({ theme }) => theme.cardBackground};
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 35%; // Adjust width as needed
`;

const ToggleSwitch = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 20px 0;

  input {
    display: none;
  }

  .toggle {
    position: relative;
    width: 50px;
    height: 24px;
    background: ${({ theme }) => theme.borderColor};
    border-radius: 50px;
    transition: background 0.3s;
    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: ${({ theme }) => theme.cardBackground};
      transition: transform 0.3s;
    }
  }

  input:checked + .toggle {
    background: ${({ theme }) => theme.color};
    &::after {
      transform: translateX(26px);
    }
  }
`;

// Custom dropdown using react-window with search functionality
const Dropdown = ({ countries, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filter countries based on the search term
  const filteredCountries = countries.filter(country =>
    country.Country.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const Row = ({ index, style }) => (
    <div style={style}>
      <button onClick={() => onSelect(filteredCountries[index])}>
        {filteredCountries[index].Country}
      </button>
    </div>
  );

  return (
    <div>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ width: '100%', marginBottom: '10px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
      />
      {filteredCountries.length === 0 ? (
        <p>No countries found</p>
      ) : (
        <List
          height={300}
          itemCount={filteredCountries.length}
          itemSize={35}
          width="100%"
        >
          {Row}
        </List>
      )}
    </div>
  );
};

const GlobalPage = () => {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' ? darkTheme : lightTheme;
  });

  const [hovered, setHovered] = useState(null);
  const [userCountry, setUserCountry] = useState('');
  const [userCountryCode, setUserCountryCode] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [countryMapData, setCountryMapData] = useState(null);
  const [factbookData, setFactbookData] = useState([]);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCountryMap, setShowCountryMap] = useState(false); // New state for toggle

  // Fetch user's country
  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        setUserCountry(data.country_name);
        setUserCountryCode(data.country);
        setSelectedCountry(data.country_name)
      })
      .catch(console.error);
  }, []);

  // Load country map data whenever the selected country changes
  useEffect(() => {
    const loadCountryMap = async () => {
      if (selectedCountryCode) {
        try {
          let CountryMap = await import(`../assets/maps/${selectedCountry.toLowerCase()}.json`);
          setCountryMapData(CountryMap);
          
        } catch (error) {
          try{
          let CountryMap = await import(`../assets/maps/${selectedCountryCode.toLowerCase()}.json`);
          setCountryMapData(CountryMap);
          
          }catch(error){
          console.error("Error loading country map:", error);
          setCountryMapData(null);
          }
        }
      }
    };
    loadCountryMap();
  }, [selectedCountryCode]);

  // Define layer properties to handle mouse events
  const layerProps = {
    onMouseEnter: ({ target }) => {
      setHovered(target.attributes.name.value);
    },
    onMouseLeave: () => {
      setHovered(null);
    },
    onClick: ({ target }) => {
      setSelectedCountryCode(target.id);
      setSelectedCountry(target.attributes.name.value);
    },
  };

  // Fetch CIA Factbook data
  useEffect(() => {
    const fetchFactbookData = async () => {
      try {
        const { data, error } = await supabase
          .from('CIA_Factbook') // Replace with your actual table name
          .select('Country, Value');

        if (error) throw error;
        setFactbookData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFactbookData();
  }, []);

  const handleSelectCountry = (country) => {
    console.log(country);
    
    setSelectedCountry(country.Country);
    setSelectedCountryCode(country.Country);
    setValue(country.Value);
    setShowCountryMap(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header />
        
        {hovered && <p>Hovered Country: {hovered}</p>}
        {/* <p>Your Country: {userCountry}</p> */}
        <ToggleSwitch>
          <input 
            type="checkbox" 
            checked={showCountryMap} 
            onChange={() => setShowCountryMap(!showCountryMap)} 
          />
          <span className="toggle" />
        </ToggleSwitch>
        <SideBySideContainer>
          <DropdownContainer>
            <h2>Select a Country</h2>
            <Dropdown countries={factbookData} onSelect={handleSelectCountry} />
            
          </DropdownContainer>

          <Map>
          {!showCountryMap ?
            <VectorMap 
              {...worldLowRes} 
              layerProps={layerProps}
              currentLayers={userCountryCode ? [userCountryCode.toLowerCase()] : []}
            /> :
           <>
            {countryMapData ? (
              <CountryMapContainer>
                <h3>Map of {selectedCountry?.toUpperCase()}</h3>
                <VectorMap {...countryMapData} />
              </CountryMapContainer>
            ) : 'Map Not Available'}
            <p>{value}</p>
            </>
          }
          </Map>
          
        </SideBySideContainer>
      </Container>
    </ThemeProvider>
  );
};

export default GlobalPage;
