import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { 
  calculateRSI, 
  calculateSMA, 
  calculateEMA, 
  calculateWMA, 
  calculateMACD, 
  calculateBollingerBands 
} from '../../Components/Common/indicators';

// Function to check if a date is a weekend
const isWeekend = (date) => {
  const day = new Date(date).getDay();
  return day === 0 || day === 6; // Sunday (0) or Saturday (6)
};

// Function to filter out weekends from the data
const filterWeekends = (data) => {
  return data.filter(item => !isWeekend(item.date));
};

const ApexGradientChartWithVolume = ({ data, lineColor, selectedIndicator,symbol }) => {
  // Handle no data scenario
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const sortedData = data.slice().sort((a, b) => new Date(a.date) - new Date(b.date));
  const filteredData = filterWeekends(sortedData);

  // Handle scenario where no data remains after filtering
  if (filteredData.length === 0) {
    return <div>No data after filtering weekends</div>;
  }

  // Create price series data
  const priceSeries = [
    {
      name: symbol,
      data: filteredData.map((chart) => ({
        x: chart.date,
        y: chart.close,
      })),
      stroke: {
        width: 1, // Adjusted width of the price line
      },
    },
  ];

  // Create volume series data
  const volumeSeries = [
    {
      name: 'Volume',
      data: filteredData.map((chart) => ({
        x: chart.date,
        y: chart.volume,
      })),
    },
  ];

  // Initialize indicator values as an array
  let indicatorSeries = [];

  // Calculate the selected indicator values if one is selected
  if (selectedIndicator) {
    let indicatorValues = [];
    switch (selectedIndicator) {
      case 'RSI':
        indicatorValues = calculateRSI(filteredData);
        indicatorSeries.push({
          name: 'RSI',
          data: filteredData.map((chart, index) => ({
            x: chart.date,
            y: indicatorValues[index],
          })).filter(point => point.y !== undefined),
          stroke: {
            width: 1, // Width of the RSI line
          },
        });
        break;
      case 'SMA':
        indicatorValues = calculateSMA(filteredData);
        indicatorSeries.push({
          name: 'SMA',
          data: filteredData.map((chart, index) => ({
            x: chart.date,
            y: indicatorValues[index],
          })).filter(point => point.y !== undefined),
          stroke: {
            width: 1, // Width of the SMA line
          },
        });
        break;
      case 'EMA':
        indicatorValues = calculateEMA(filteredData);
        indicatorSeries.push({
          name: 'EMA',
          data: filteredData.map((chart, index) => ({
            x: chart.date,
            y: indicatorValues[index],
          })).filter(point => point.y !== undefined),
          stroke: {
            width: 1, // Width of the EMA line
          },
        });
        break;
      case 'WMA':
        indicatorValues = calculateWMA(filteredData);
        indicatorSeries.push({
          name: 'WMA',
          data: filteredData.map((chart, index) => ({
            x: chart.date,
            y: indicatorValues[index],
          })).filter(point => point.y !== undefined),
          stroke: {
            width: 1, // Width of the WMA line
          },
        });
        break;
      case 'MACD':
        indicatorValues = calculateMACD(filteredData);
        indicatorSeries.push({
          name: 'MACD',
          data: filteredData.map((chart, index) => ({
            x: chart.date,
            y: indicatorValues[index - 12]?.macd, // Adjust for MACD
          })).filter(point => point.y !== undefined),
          stroke: {
            width: 1, // Width of the MACD line
          },
        });
        break;
      case 'BB':
        // Calculate Bollinger Bands based on filtered data
        const { upperBand, middleBand, lowerBand } = calculateBollingerBands(filteredData);
        indicatorSeries.push(
          {
            name: 'Upper Band',
            data: filteredData.map((chart, index) => ({
              x: chart.date,
              y: upperBand[index],
            })).filter(point => point.y !== undefined),
            stroke: {
              width: 1, // Adjusted width of the Upper Band line
            },
          },
          {
            name: 'Middle Band',
            data: filteredData.map((chart, index) => ({
              x: chart.date,
              y: middleBand[index],
            })).filter(point => point.y !== undefined),
            stroke: {
              width: 1, // Adjusted width of the Middle Band line
            },
          },
          {
            name: 'Lower Band',
            data: filteredData.map((chart, index) => ({
              x: chart.date,
              y: lowerBand[index],
            })).filter(point => point.y !== undefined),
            stroke: {
              width: 1, // Adjusted width of the Lower Band line
            },
          }
        );
        break;
      default:
        console.warn('No valid indicator selected:', selectedIndicator);
        break;
    }
  }

  // Options for the line chart
  const priceOptions = {
    chart: {
      id: 'priceChart',
      type: 'line',
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    colors: [lineColor, ...(selectedIndicator ? ['blue', 'yellow', 'blue'] : [])],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: selectedIndicator ? 1.5 : 1, // Change line width here (2 for indicators, 3 for price line)
      // Removed width here since we set it in individual series
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: [lineColor, '#FF6347'],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#ffffff',
        },
        formatter: (value) => `$${value}`,
      },
      crosshairs: {
        show: true,
      },
    },
    tooltip: {
      theme: 'dark',
      y: {
        formatter: (value) => `$${value}`,
      },
    },
  };

  // Options for the volume chart
  const volumeOptions = {
    chart: {
      id: 'volumeChart',
      type: 'area',
      height: 150,
      brush: {
        enabled: true,
        target: 'priceChart', // Targeting the price chart for zooming
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#00BFFF'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: '#ffffff',
          fontSize: '12px',
        },
        formatter: (value) => {
          const date = new Date(value);
          return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      theme: 'dark',
      y: {
        formatter: () => '', // Hide volume value in tooltip
      },
    },
  };

  return (
    <div>
      {/* Line Chart */}
      <div id="price-chart">
        <ReactApexChart
          options={priceOptions}
          series={[priceSeries[0], ...indicatorSeries]} // Spread indicatorSeries correctly
          type="line"
          height={350}
        />
      </div>

      {/* Volume Chart (Brush Area Chart) */}
      <div id="volume-chart">
        <ReactApexChart
          options={volumeOptions}
          series={volumeSeries}
          type="area"
          height={150}
        />
      </div>
    </div>
  );
};

export default ApexGradientChartWithVolume;
