import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import bcrypt from 'bcryptjs';

// Supabase client
import { supabase } from "../../supabaseClient"; 

const Register = () => {
    const history = useNavigate();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            first_name: '',
            last_name: '',
            password: '',            
            dob: '',
            phone: '',
            state: '',
            country: '',
            gender: '',
            occupation: '',
            company: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            first_name: Yup.string().required("Please Enter Your First Name"),
            last_name: Yup.string().required("Please Enter Your Last Name"),
            password: Yup.string().required("Please Enter Your Password"),
            
            dob: Yup.string().required("Please Enter Your Date of Birth"),
            phone: Yup.string().required("Please Enter Your Phone Number"),
            
        }),
        onSubmit: async (values) => {
            try {
                const { data: authData, error: authError } = await supabase.auth.signUp({
                    email: values.email,
                    password: values.password
                });

                if (authError) {
                    toast.error(`Registration Error: ${authError.message}`);
                    return;
                }

                const salt = bcrypt.genSaltSync(10);
                const passwordHash = bcrypt.hashSync(values.password, salt);

                const { data: insertData, error: insertError } = await supabase
                    .from('vegaveta_users')
                    .insert([{
                        first_name: values.first_name,
                        last_name: values.last_name,
                        email: values.email,                        
                        password_hash: passwordHash,
                        date_of_birth: values.dob,
                        phone_number: values.phone,
                        state_province: values.state,
                        country: values.country,
                        gender: values.gender,
                        occupation: values.occupation,
                        company_name: values.company
                    }]);

                if (insertError) {
                    toast.error(`Database Error: ${insertError.message}`);
                } else {
                    toast.success("Registered successfully! Redirecting to login...");
                    setTimeout(() => history("/login"), 3000);
                }

            } catch (error) {
                toast.error("An unexpected error occurred");
            }
        }
    });

    useEffect(() => {
        document.title = "Basic SignUp | Velzon - React Admin & Dashboard Template";
    }, []);

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="20" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Create New Account</h5>
                                            <p className="text-muted">Get your free Velzon account now</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation" action="#">

                                                {/* First Name and Last Name */}
                                                <Row>
                                                    <Col md={6}>
                                                        <Label htmlFor="first_name" className="form-label">First Name <span className="text-danger">*</span></Label>
                                                        <Input
                                                            name="first_name"
                                                            type="text"
                                                            placeholder="Enter first name"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.first_name || ""}
                                                            invalid={validation.touched.first_name && validation.errors.first_name}
                                                        />
                                                        {validation.touched.first_name && validation.errors.first_name && (
                                                            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                                                        )}
                                                    </Col>
                                                    <Col md={6}>
                                                        <Label htmlFor="last_name" className="form-label">Last Name <span className="text-danger">*</span></Label>
                                                        <Input
                                                            name="last_name"
                                                            type="text"
                                                            placeholder="Enter last name"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.last_name || ""}
                                                            invalid={validation.touched.last_name && validation.errors.last_name}
                                                        />
                                                        {validation.touched.last_name && validation.errors.last_name && (
                                                            <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                                                        )}
                                                    </Col>
                                                </Row>

                                                {/* Email and Password */}
                                                <Row className="mt-3">
                                                    <Col md={6}>
                                                        <Label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></Label>
                                                        <Input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            placeholder="Enter email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            invalid={validation.touched.email && validation.errors.email}
                                                        />
                                                        {validation.touched.email && validation.errors.email && (
                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                        )}
                                                    </Col>
                                                    <Col md={6}>
                                                        <Label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></Label>
                                                        <Input
                                                            name="password"
                                                            type="password"
                                                            placeholder="Enter password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            invalid={validation.touched.password && validation.errors.password}
                                                        />
                                                        {validation.touched.password && validation.errors.password && (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        )}
                                                    </Col>
                                                </Row>

                                                {/* Date of Birth and Phone */}
                                                <Row className="mt-3">
                                                    <Col md={6}>
                                                        <Label htmlFor="dob" className="form-label">Date of Birth <span className="text-danger">*</span></Label>
                                                        <Input
                                                            name="dob"
                                                            type="date"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.dob || ""}
                                                            invalid={validation.touched.dob && validation.errors.dob}
                                                        />
                                                        {validation.touched.dob && validation.errors.dob && (
                                                            <FormFeedback type="invalid">{validation.errors.dob}</FormFeedback>
                                                        )}
                                                    </Col>
                                                    <Col md={6}>
                                                        <Label htmlFor="phone" className="form-label">Phone Number <span className="text-danger">*</span></Label>
                                                        <Input
                                                            name="phone"
                                                            type="text"
                                                            placeholder="Enter phone number"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.phone || ""}
                                                            invalid={validation.touched.phone && validation.errors.phone}
                                                        />
                                                        {validation.touched.phone && validation.errors.phone && (
                                                            <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                                        )}
                                                    </Col>
                                                </Row>

                                                {/* State and Country */}
                                                <Row className="mt-3">
                                                    <Col md={6}>
                                                        <Label htmlFor="state" className="form-label">State </Label>
                                                        <Input
                                                            name="state"
                                                            type="text"
                                                            placeholder="Enter state"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.state || ""}
                                                            invalid={validation.touched.state && validation.errors.state}
                                                        />
                                                        {validation.touched.state && validation.errors.state && (
                                                            <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                                        )}
                                                    </Col>
                                                    <Col md={6}>
                                                        <Label htmlFor="country" className="form-label">Country </Label>
                                                        <Input
                                                            name="country"
                                                            type="text"
                                                            placeholder="Enter country"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.country || ""}
                                                            invalid={validation.touched.country && validation.errors.country}
                                                        />
                                                        {validation.touched.country && validation.errors.country && (
                                                            <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                                                        )}
                                                    </Col>
                                                </Row>

                                                {/* Gender and Occupation */}
                                                <Row className="mt-3">
                                                    <Col md={6}>
                                                        <Label htmlFor="gender" className="form-label">Gender </Label>
                                                        <Input
                                                            name="gender"
                                                            type="select"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.gender || ""}
                                                            invalid={validation.touched.gender && validation.errors.gender}
                                                        >
                                                            <option value="">Select gender</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                            <option value="other">Other</option>
                                                        </Input>
                                                        {validation.touched.gender && validation.errors.gender && (
                                                            <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                                                        )}
                                                    </Col>
                                                    <Col md={6}>
                                                        <Label htmlFor="occupation" className="form-label">Occupation </Label>
                                                        <Input
                                                            name="occupation"
                                                            type="text"
                                                            placeholder="Enter occupation"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.occupation || ""}
                                                            invalid={validation.touched.occupation && validation.errors.occupation}
                                                        />
                                                        {validation.touched.occupation && validation.errors.occupation && (
                                                            <FormFeedback type="invalid">{validation.errors.occupation}</FormFeedback>
                                                        )}
                                                    </Col>
                                                </Row>

                                                {/* Company Name */}
                                                <Row className="mt-3">
                                                    <Col md={12}>
                                                        <Label htmlFor="company" className="form-label">Company Name </Label>
                                                        <Input
                                                            name="company"
                                                            type="text"
                                                            placeholder="Enter company name"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.company || ""}
                                                            invalid={validation.touched.company && validation.errors.company}
                                                        />
                                                        {validation.touched.company && validation.errors.company && (
                                                            <FormFeedback type="invalid">{validation.errors.company}</FormFeedback>
                                                        )}
                                                    </Col>
                                                </Row>

                                                {/* Terms and Privacy Checkboxes */}
                                                <Row className="mt-4">
                                                    <Col md={12}>
                                                        <div className="form-check">
                                                            <Input
                                                                type="checkbox"
                                                                name="terms"
                                                                id="terms"
                                                                className="form-check-input"
                                                                onChange={validation.handleChange}
                                                            />
                                                            <Label className="form-check-label" htmlFor="terms">
                                                                I agree to the <Link to="#" className="text-primary">terms and conditions</Link>.
                                                            </Label>
                                                        </div>

                                                        <div className="form-check mt-2">
                                                            <Input
                                                                type="checkbox"
                                                                name="privacy"
                                                                id="privacy"
                                                                className="form-check-input"
                                                                onChange={validation.handleChange}
                                                            />
                                                            <Label className="form-check-label" htmlFor="privacy">
                                                                I agree to the <Link to="#" className="text-primary">privacy policy</Link>.
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className="mt-4">
                                                    <button className="btn btn-success w-100" type="submit">Sign Up</button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Already have an account?{" "}
                                        <Link to="/login" className="fw-semibold text-primary text-decoration-underline">Sign in</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
            <ToastContainer/>
        </React.Fragment>
    );
};

export default Register;
