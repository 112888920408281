import React, { useEffect, useState } from "react";
import axios from "axios";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import Header from "../../pages/Header/index"; // Import the Header component
import Footer from "./Footer";

const lightTheme = {
  background: "#ffffff",
  color: "#000000",
  cardBackground: "#f5f5f5",
  cardColor: "#000000",
  buttonBackground: "#d97c6e",
  buttonHoverBackground: "#b65e4d",
};

const darkTheme = {
  background: "#222122",
  color: "#ffffff",
  cardBackground: "#222122",
  cardColor: "#ffffff",
  buttonBackground: "#d97c6e",
  buttonHoverBackground: "#b65e4d",
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s, color 0.3s;
  }
`;

const Container = styled.div`
  padding: 50px;
  background-color: ${({ theme }) => theme.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  font-family: 'Lora', serif;
`;

const RefreshIcon = styled.span`
  font-size: 2em;
  color: #AC544C;
  cursor: pointer;
  margin-left: 5px; /* Space from elements on its left */
  margin-bottom: 20px; /* Space from elements below */
  display: inline-block; /* Ensure it is aligned to the left */
  &:hover {
    color: ${({ theme }) => theme.buttonHoverBackground};
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px; /* Adjust based on your design */
  width: 100%;
`;

const Main = styled.div`
  background-color: ${({ theme }) => theme.background};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SingleColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.cardBackground};
  padding: 20px;
  border-radius: 8px;  
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  ${({ isExpanded }) => isExpanded && `
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  `}
`;

const FullCard = styled(Card)`
  grid-column: span 2; /* Makes the card span across the entire grid width */
`;

const Image = styled.img`
  width: 40vh;
  height: auto;
  border-radius: 8px;
  margin-left: 20px;
`;

const Image1 = styled.img`
  width: 20vh;
  height: auto;
  border-radius: 8px;
  margin-left: 20px;
`;

const Title = styled.h2`
  font-size: 1.5em;
  margin-bottom: 10px;
  font-family: 'Lora', serif;
  color: ${({ theme }) => theme.color};
`;

const PublishedDate = styled.p`
  font-size: 0.9em;
  margin-bottom: 10px;
  color: gray;
`;

const Text = styled.p`
  margin-bottom: 10px;
  flex: 1; // Ensure text takes up remaining space
  color: ${({ theme }) => theme.color};
`;

const ReadMore = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const ReadLess = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const Heading = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.color};
`;

const NewsSectionCrypto = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedCardIndex, setExpandedCardIndex] = useState(null);


  const sanitizeHTML = (html) => {
    return {
      __html: html.replace(/<\/?[^>]+(>|$)/g, '')
    };
  };

  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      console.log(savedTheme === 'dark' ? darkTheme : lightTheme);
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
      
      
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });


  const fetchArticles = () => {
    // useEffect(() => {
    axios
      .get("https://financialmodelingprep.com/api/v4/crypto_news?page=0&apikey=11cd608ea5136b9fdb748e3a80c1724e")
      .then((response) => {
        const sortedArticles = response.sort((a, b) => new Date(b.publishedDate) - new Date(a.publishedDate));
        console.log(sortedArticles);
        
        setArticles(sortedArticles);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the news data:", error);
        setError(error);
        setLoading(false);
      });
    // }, []);
  }
  useEffect(() => {
    fetchArticles();
  }, []);

  const handleCardClick = (index) => {
    setExpandedCardIndex(expandedCardIndex === index ? null : index);
  };

  if (loading) {
    return <Container>Loading...</Container>;
  }

  if (error) {
    return <Container>Error: {error.message}</Container>;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Main>
        <Header />
        <Container>

          <ContentWrapper>
            {/* <RefreshIcon onClick={fetchArticles}>&#x21bb;</RefreshIcon> */}
            <Grid>
              {articles.length > 0 && (
                <>
                  {articles.length > 0 && (
                    <FullCard>
                      <div>
                        <Title>{articles[0].title}</Title>
                        <Text dangerouslySetInnerHTML={sanitizeHTML(`Source: ${articles[0].site}`)} />
                        {/* <Text dangerouslySetInnerHTML={sanitizeHTML(`Author: ${articles[0].author}`)} /> */}
                        <PublishedDate>{articles[0].date}</PublishedDate>
                        <Text dangerouslySetInnerHTML={sanitizeHTML(articles[0].text)} />
                      </div>
                      {articles[0].image && <Image src={articles[0].image} alt={articles[0].title} />}
                    </FullCard>
                  )}
                  {articles.slice(1, 5).map((article, index) => (
                    <Card key={index} isExpanded={expandedCardIndex === index + 1} onClick={() => handleCardClick(index + 1)}>
                      <div>
                        <Title>{article.title}</Title>
                        <Text dangerouslySetInnerHTML={sanitizeHTML(`Source: ${article.site}`)} />
                        {/* <Text dangerouslySetInnerHTML={sanitizeHTML(`Author: ${article.author}`)} /> */}
                        <PublishedDate>{article.date}</PublishedDate>
                        <Text dangerouslySetInnerHTML={sanitizeHTML(article.text)} />
                      </div>
                      {article.image && <Image1 src={article.image} alt={article.title} />}
                    </Card>
                  ))}
                  {articles.length > 5 && articles.slice(5).map((article, index) => (
                    <Card key={index + 5} isExpanded={expandedCardIndex === index + 5} onClick={() => handleCardClick(index + 5)}>
                      <div>
                        <Title>{article.title}</Title>
                        <Text dangerouslySetInnerHTML={sanitizeHTML(`Source: ${article.site}`)} />
                        {/* <Text dangerouslySetInnerHTML={sanitizeHTML(`Author: ${article.author}`)} /> */}
                        <PublishedDate>{article.date}</PublishedDate>
                        {expandedCardIndex === index + 5 ? (
                          <>
                            <Text dangerouslySetInnerHTML={sanitizeHTML(article.text)} />
                            <ReadLess onClick={(e) => { e.stopPropagation(); handleCardClick(null); }}>Read less</ReadLess>
                          </>
                        ) : (
                          <>
                            <Text dangerouslySetInnerHTML={sanitizeHTML(article.text.split(' ').slice(0, 10).join(' '))} />
                            <ReadMore onClick={(e) => { e.stopPropagation(); handleCardClick(index + 5); }}>Read more</ReadMore>
                          </>
                        )}
                      </div>
                      {/* {article.image && <Image src={article.image} alt={article.title} />} */}
                    </Card>
                  ))}
                </>
              )}
            </Grid>
            <br />
            {/* <SingleColumnGrid> */}

            {/* </SingleColumnGrid> */}
          </ContentWrapper>
        </Container>
        <Footer />
      </Main>
    </ThemeProvider>
  );
};

export default NewsSectionCrypto;
