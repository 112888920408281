import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from './Header';  // Assuming you have a Header component
import { supabase } from '../supabaseClient';  // Make sure this is correctly configured
import axios from 'axios';  // Axios for API calls

// Global Styles
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
};

const darkTheme = {
  background: '#222122',
  color: '#f0f0f0',
  primary: '#AC544C',
  secondary: '#b06252',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 50px;
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.primary};
    text-align: left;
  }

  th {
    background-color: ${({ theme }) => theme.secondary};
    color: #fff;
  }

  td {
    background-color: ${({ theme }) => theme.background};
  }
`;

const PortfolioPage = () => {
  const [portfolioData, setPortfolioData] = useState({
    symbol: '',
    shares: '',
    weight: '',
    purchasePrice: '',
    currency: 'USD', // Default currency
  });

  const [portfolioItems, setPortfolioItems] = useState([]);
  const [totalPortfolioValue, setTotalPortfolioValue] = useState(0);
  const [error, setError] = useState(null);

  const API_KEY = 'YOUR_API_KEY';  // Replace with your actual API key from Financial Modeling Prep

  // Fetch data from Supabase
  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        const { data, error } = await supabase
          .from('PortfolioComposition')  // Replace with your actual table name
          .select('*');

        if (error) {
          setError("Error fetching portfolio from database: " + error.message);
        } else {
          setPortfolioItems(data); // Set the fetched data
        }
      } catch (err) {
        setError("An unexpected error occurred: " + err.message);
      }
    };

    fetchPortfolioData();
  }, []);

  // Fetch current stock price for a symbol
  const fetchCurrentStockPrice = async (symbol) => {
    try {
      const response = await axios.get(
        `https://financialmodelingprep.com/api/v3/quote-short/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e`
      );
      return response[0]?.price || 0;
    } catch (error) {
      console.error(`Error fetching price for ${symbol}:`, error);
      return 0;
    }
  };

  // Calculate portfolio value
  useEffect(() => {
    const calculatePortfolioValue = async () => {
      let totalValue = 0;
      
      for (const item of portfolioItems) {
        if(item.shares > 0){
        const currentPrice = await fetchCurrentStockPrice(item.symbol);
        totalValue += item.shares * currentPrice;
        }
      }

      setTotalPortfolioValue(totalValue);
    };

    if (portfolioItems.length > 0) {
      calculatePortfolioValue();
    }
  }, [portfolioItems]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPortfolioData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Add the new item to the table
    setPortfolioItems((prevItems) => [...prevItems, portfolioData]);

    // Submit to Supabase
    try {
      const { error } = await supabase
        .from('PortfolioComposition')  // Replace with your actual table name
        .insert([
          {
            symbol: portfolioData.symbol,
            shares: portfolioData.shares,
            weight: portfolioData.weight,
            purchase_price: portfolioData.purchasePrice,
            currency: portfolioData.currency,
          },
        ]);

      if (error) {
        setError("Error adding portfolio to database: " + error.message);
      } else {
        setError(null);
        console.log("Portfolio added to database.");
      }
    } catch (err) {
      setError("An unexpected error occurred: " + err.message);
    }

    // Reset form fields
    setPortfolioData({
      symbol: '',
      shares: '',
      weight: '',
      purchasePrice: '',
      currency: 'USD',
    });
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <Container>
        <Header />
        <MainContent>
          <h1>Create Portfolio</h1>
          <form onSubmit={handleSubmit} style={{ width: '50%' }}>
            <FormGroup>
              <Label>Symbol:</Label>
              <Input
                type="text"
                name="symbol"
                value={portfolioData.symbol}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Shares:</Label>
              <Input
                type="number"
                name="shares"
                value={portfolioData.shares}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Weight (%):</Label>
              <Input
                type="number"
                step="0.01"
                name="weight"
                value={portfolioData.weight}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Purchase Price:</Label>
              <Input
                type="number"
                step="0.01"
                name="purchasePrice"
                value={portfolioData.purchasePrice}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Currency:</Label>
              <Select
                name="currency"
                value={portfolioData.currency}
                onChange={handleChange}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
              </Select>
            </FormGroup>
            <Button type="submit">Add to Portfolio</Button>
          </form>

          {error && <p style={{ color: 'red' }}>{error}</p>}

          {/* Table to display portfolio data */}
          {portfolioItems.length > 0 ? (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Shares</th>
                    <th>Weight (%)</th>
                    <th>Purchase Price</th>
                    <th>Currency</th>
                  </tr>
                </thead>
                <tbody>
                {portfolioItems
                  .filter(item => item.shares > 0)
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{item.symbol}</td>
                      <td>{item.shares}</td>
                      <td>{item.weight}</td>
                      <td>{item.purchase_price}</td>
                      <td>{item.currency}</td>
                    </tr>
                  ))
                }

                </tbody>
              </Table>
              <br/>
              <Button>Total Portfolio Value: ${totalPortfolioValue.toFixed(2)}</Button>
            </>
          ) : (
            <p>No portfolio data available.</p>
          )}
        </MainContent>
      </Container>
    </ThemeProvider>
  );
};

export default PortfolioPage;
